import {Controller} from "@hotwired/stimulus";

export default class CookieboxController extends Controller {
    isSet = false
    connect() {
        if(!this.checkIfCanInitialise()) {
            return
        }

        if(!this.isSetCookie(`cntb_set_${this.element.getAttribute('data-configuration-uid')}`)) {
            let initialiseOpen = true
            if(window.location.href.indexOf("impressum") > -1) {
                initialiseOpen = false
            }
            if(window.location.href.indexOf("datenschutz") > -1) {
                initialiseOpen = false
            }
            if( document.body.classList.contains('disable-cookienotice')) {
                initialiseOpen = false
            }
            if(initialiseOpen) {
                this.open()
            }
        } else {
            this.isSet = true
            this.loadCookieContent()
        }

        const openElements = document.getElementsByClassName('cookieNotice-open')

        for(const openElement of openElements) {
            openElement.addEventListener('click', event => {
                event.preventDefault()
                this.open()
            })
        }

        super.connect();
    }
    
    loadCookieContent() {
        let cookies = this.getCookie(`cntb_set_${this.element.getAttribute('data-configuration-uid')}`).split(',')
        console.log(cookies);
        if(cookies.includes('recaptcha')) {
            const recaptchaCookieElements = document.getElementsByClassName('cookienotice_placeholder_recaptcha desactivated')

            for(const recaptchaCookieElement of recaptchaCookieElements) {
                const recaptchaElement = recaptchaCookieElement.getElementsByClassName('g-recaptcha')[0]
                recaptchaCookieElement.parentElement.appendChild(recaptchaElement)
                recaptchaCookieElement.remove()
            }

            const recaptchaScript = document.createElement('script')
            recaptchaScript.src = 'https://www.google.com/recaptcha/api.js'
            recaptchaScript.setAttribute('async', null)
            recaptchaScript.setAttribute('defer', null)

            document.body.appendChild(recaptchaScript)
        }
    }

    isSetCookie(name) {
        return decodeURIComponent(document.cookie).indexOf(name) !== -1;
    }

    checkIfCanInitialise() {
        return !/adidxbotc|Applebot\/|archive.org_bot|asterias\/|Baiduspider\/|bingbot\/|BingPreview\/|DuckDuckBot\/|FAST-WebCrawler\/|Feedspot|Feedspotbot\/|Google Page Speed Insights|Google PP|Google Search Console|Google Web Preview|Googlebot\/|Googlebot-Image\/|Googlebot-Mobile\/|Googlebot-News|Googlebot-Video\/|Google-SearchByImage|Google-Structured-Data-Testing-Tool|Chrome-Lighthouse|heritrix\/|iaskspider\/|Mediapartners-Google|msnbot\/|msnbot-media\/|msnbot-NewsBlogs\/|msnbot-UDiscovery\/|SEMrushBot|special_archiver\/|Y!J-ASR\/|Y!J-BRI\/|Y!J-BRJ\/YATS|Y!J-BRO\/YFSJ|Y!J-BRW\/|Y!J-BSC\/|Yahoo! Site Explorer Feed Validator|Yahoo! Slurp|YahooCacheSystem|Yahoo-MMCrawler\/|YahooSeeker\//i.test(navigator.userAgent);
    }

    open() {
        const cookieHighlights = this.element.getElementsByClassName('cookiehighlight')

        for(const cookieHighlight of cookieHighlights) {
            cookieHighlight.classList.remove('cookiehighlight')
        }

        document.body.classList.add('show-cookienotice')
    }

    setCookie(cname,value) {
        const d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000));
        document.cookie = cname+"="+value+";expires=" + d.toUTCString() + ";path=/";
    }

    getCookie(cname) {
        const ca = document.cookie.split(";"),
            name = `${cname}=`;
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    loadSetCookies() {
        const setCookieRights = this.getCookie(`cntb_set_${this.cookieBox.getAttribute('data-configuration-uid')}`).split(',')

        for(const setCookieRight of setCookieRights) {
            if(setCookieRight === '') {
                continue
            }

            const cookieInput = this.element.querySelectorAll(`input[name="${setCookieRight}"]`)

            cookieInput.checked = true
        }
    }

    checkAll() {
        const inputs = this.element.querySelectorAll('input[type="checkbox"]')

        for(const input of inputs) {
            input.checked = true
        }
    }

    preloadInputs() {
        const requiredInputs = this.cookieBox.querySelectorAll('div[data-key="required"] input')

        for(const requiredInput of requiredInputs) {
            requiredInput.setAttribute('checked', 'checked')
            requiredInput.setAttribute('readonly', 'readonly')
            requiredInput.setAttribute('disabled', 'disabled')
        }

        let inputs = this.cookieBox.querySelectorAll('input[data-key]')

        for(const input of inputs) {
            const parentInputs = this.cookieBox.querySelectorAll(`input[data-parent-key="${input.getAttribute('data-key')}"]`)

            for(const parentInput of parentInputs) {
                parentInput.checked = input.checked
            }
        }

        inputs = this.cookieBox.querySelectorAll('input[data-parent-key]')

        for(const input of inputs) {
            const parentInputs = this.cookieBox.querySelectorAll(`input[data-parent-key="${input.getAttribute('data-parent-key')}"]`)

            for(const parentInput of parentInputs) {
                parentInput.checked = input.checked
            }
        }
    }

    saveAll(event) {
        this.checkAll()
        this.saveAndClose(event)
    }

    close() {
        document.body.classList.remove('show-cookienotice')
    }

    saveAndClose(event) {
        event.preventDefault()
        let cookies = ''

        const inputs = this.element.querySelectorAll('input[type="checkbox"]')

        for(const input of inputs) {
            if(!input.checked) {
                continue
            }
            if(cookies === '') {
                cookies = ','
            }
            cookies += `${input.getAttribute('name')},`
        }

        cookies = cookies.substring(1)

        this.setCookie(`cntb_set_${this.element.getAttribute('data-configuration-uid')}`, cookies)

        document.body.classList.remove('show-cookienotice')

        if(this.isSet) {
            location.reload()
        }

        this.loadCookieContent()
    }

    showMore(event) {
        event.preventDefault()

        const moreDetails = event.target.parentElement.getElementsByClassName('cookienotice-cookie-items')[0]

        moreDetails.classList.toggle('d-block')
    }
}