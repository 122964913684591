import {Controller} from "@hotwired/stimulus";

export default class BookingController extends Controller {
    arrivalDateInput = this.element.querySelector("[name='tx_smediamonteurzimmer_monteurzimmer[data][arrivalDate]']")
    departureDateInput = this.element.querySelector("[name='tx_smediamonteurzimmer_monteurzimmer[data][departureDate]']")
    updateDateInputs() {
        if(this.arrivalDateInput.value !== '') {
            this.departureDateInput.min = this.arrivalDateInput.value
        }
        if(this.departureDateInput.value !== '') {
            this.arrivalDateInput.max = this.departureDateInput.value
        }
    }

    submit(event) {
        if(!this.element.checkValidity()) {
            return
        }

        const recaptcha = this.element.querySelector('#g-recaptcha-response')

        if(recaptcha.value === '') {
            event.preventDefault()
        }
    }
}