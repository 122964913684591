import {Application} from "@hotwired/stimulus";
import BookingController from "./Controller/bookingController";
import SuggestController from "./Controller/suggestController";
import TouchedController from "./Controller/touchedController";
import CookieboxController from "./Controller/cookieboxController";

const application = Application.start()

application.debug = location.hostname.endsWith('.ddev.site')

application.register('booking', BookingController)
application.register('suggest', SuggestController)
application.register('touched', TouchedController)
application.register('cookiebox', CookieboxController)