
let win = $(window); //this = window
let $nav = $('#nav');

if (win.width() <= 1199) { 
    if (!$nav.parent().hasClass('mobile-nav')) {
        $nav.parent().addClass('mobile-nav')

        $('#header .burger').on('click', function() {
            openMobileNav();
        });
        $('#header .cross').on('click', function() {
            closeMobileNav();
        });
    }
}


$(window).on('resize', function(){

    if (win.width() <= 1199) { 
        if (!$nav.parent().hasClass('mobile-nav')) {
            $nav.parent().addClass('mobile-nav')

            $('#header .burger').on('click', function() {
                openMobileNav();
            });
            $('#header .cross').on('click', function() {
                closeMobileNav();
            });
        }
    }
    else {
        if ($nav.parent().hasClass('mobile-nav')) {
            $nav.parent().removeClass('mobile-nav')
        }
    }
});

function openMobileNav() {
    if($nav.parent().hasClass('mobile-nav')) {
        $nav.parent().toggleClass('open')
    }
}

function closeMobileNav() {
    if($nav.parent().hasClass('open')) {
        $nav.parent().removeClass('open')
    }
}