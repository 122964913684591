import { Controller } from '@hotwired/stimulus';

export default class SuggestController extends Controller {

    input;
    select;
    selected = null;

    connect() {
        this.element.addEventListener('mousedown', (e) => this.onClick(e));
        this.input = this.element.getElementsByTagName('input')[0];
        this.select = this.element.getElementsByClassName('suggestselect')[0];
        this.input.addEventListener('focus', (e) => this.onFocus(e));
        this.input.addEventListener('blur', (e) => this.onBlur(e));
        this.input.addEventListener('input', () => this.onChange());
        this.input.addEventListener('keydown', (e) => this.onKeyDown(e));
        this.onChange();
    }

    onClick(event) {
        if (event.target.classList.contains("element")) {
            this.selected = event.target;
            this.selectSelected();

            // event.preventDefault();
            // event.stopPropagation();
        }
    }

    onFocus(event) {
        this.select.classList.add('focus');
    }

    onBlur(event) {
        this.select.classList.remove('focus');
    }

    onChange() {
        Array.from(this.select.children)
            .forEach(element => {
                let casted = element;
                if (casted.innerText.toLowerCase().includes(this.input.value.toLowerCase())) {
                    casted.removeAttribute('hidden');
                    if (casted.classList.contains('selected')) {
                        this.selected = null;
                        casted.classList.remove('selected');
                    }
                } else {
                    casted.setAttribute('hidden', 'hidden');
                }
            })
    }

    selectSelected() {
        if (this.selected) {
            this.selected.classList.remove('selected');
            this.input.value = (this.selected).innerText;
            this.onChange();

            const event = new CustomEvent('autosuggest', {bubbles: true, detail: {
                    value: this.input.value
                }});

            this.selected.dispatchEvent(event);

            this.selected = null;
        }
    }

    onKeyDown(event) {
        switch (event.key) {
            case "ArrowDown":
                this.searchElement(true);
                event.preventDefault();
                break;
            case "ArrowUp":
                this.searchElement(false);
                event.preventDefault();
                break;
            case "Enter":
                if (this.selected) {
                    event.preventDefault();
                    this.selectSelected();
                }
                break;
            case "Tab":
                if (this.selected) {
                    this.selectSelected();
                }
                break;
        }
    }

    /**
     * @param {boolean} direction true => forward, false => backwards
     */
    searchElement(direction) {
        if (this.selected === null) {
            this.selected = (direction ? this.select.firstElementChild : this.select.lastElementChild);
            if (!this.selected.hasAttribute('hidden')) {
                this.selected.classList.add('selected');
                this.selected.scrollIntoView({block: "nearest"});
                return;
            }
        } else {
            this.selected.classList.remove('selected');
        }
        let start = this.selected;
        let element = start;
        while ((element = (direction ? this.getNextElement(element) : this.getPreviousElement(element))) !== start) {
            if (!element.hasAttribute('hidden')) {
                this.selected = element;
                this.selected.classList.add('selected');
                this.selected.scrollIntoView({block: "nearest"});
                return;
            }
        }
        this.selected.classList.add('selected');
    }

    getNextElement(current) {
        // @ts-ignore
        return current.nextElementSibling || current.parentElement.firstElementChild
    }

    getPreviousElement(current) {
        // @ts-ignore
        return current.previousElementSibling || current.parentElement.lastElementChild
    }
}
