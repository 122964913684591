import { Controller } from '@hotwired/stimulus';

export default class TouchedController extends Controller {

    fun;

    connect() {
        this.fun = () => {
            this.onTouch();
        }
        this.element.addEventListener('blur', this.fun, {passive: true})
    }

    onTouch() {
        // @ts-ignore
        this.element.removeEventListener('blur', this.fun, {passive: true});
        this.element.classList.add('touched');
    }

}
